@mixin vans-reveal {
  .reveal,
  .reveal-modal {
    .message {
      margin: 10px 0;
    }
  }

  .reveal {
    // Necessary to only override Foundation 6 fullscreen for small
    @include breakpoint($-zf-zero-breakpoint only) {
      height: auto;
      margin-left: auto;
      min-height: auto;
      width: $reveal-width;
    }
  }
}
